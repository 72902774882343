:root {
    --newsColorShadeA: rgb(163, 152, 106);
    --newsColorShadeB: rgb(183, 186, 121);
    --newsColorShadeC: rgb(232, 222, 150);
    --newsColorShadeD: rgb(232, 232, 187);
    --newsColorShadeE: rgb(255, 255, 205);
}

.newsb button {
    position: absolute;
    display: flex;
    /* Set to flex */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
    cursor: pointer;
    outline: none;
    border: 0;
    text-decoration: none;
    font-size: 1.5rem;
    color: var(--newsColorShadeA);
    font-weight: 700;
    text-transform: uppercase;
    font-family: inherit;
}

.newsb button.big-button {
    width: 1em;
    /* set the width */
    height: 1em;
    /* set the height */
    line-height: 1em;
    /* center the text vertically */
    text-align: center;
    /* center the text horizontally */
    border: 2px solid var(--newsColorShadeA);
    border-radius: 50%;
    /* make it a circle */
    background: var(--newsColorShadeE);
    transform-style: preserve-3d;
    transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

button.big-button::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--newsColorShadeC);
    border-radius: 50%;
    /* make it a circle */
    box-shadow: 0 0 0 2px var(--newsColorShadeB), 0 0.1875em 0 0 var(--newsColorShadeA);
    transform: translate3d(0, 0.1875em, -0.25em);
    transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

button.big-button:hover {
    background: var(--newsColorShadeD);
    transform: translate(0, 0.085em);
}

button.big-button:hover::before {
    transform: translate3d(0, 0.1875em, -0.25em);
}

button.big-button:active {
    transform: translate(0em, 0.1875em);
}

button.big-button:active::before {
    transform: translate3d(0, 0, -0.25em);
    box-shadow: 0 0 0 2px var(--newsColorShadeB), 0 0.0625em 0 0 var(--newsColorShadeB);
}

button.big-button.simulate-press {
    transform: translate(0em, 0.1875em);
}

button.big-button.simulate-press::before {
    transform: translate3d(0, 0, -0.25em);
    box-shadow: 0 0 0 2px var(--newsColorShadeB), 0 0.0625em 0 0 var(--newsColorShadeB);
}


.news-inner-wrapper {
    background-color: #222;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
    overflow-y: hidden;
    padding-left: 5px;
    padding-top: 20px; 
    padding-bottom: 20px; 
    padding-right: 20px; 
    height: 13vmax;
    /* background-image: url('/public/OldTV.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    position: relative;
    user-select: none;
    min-height: 160px;
}

.news-inner-wrapper li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: #eee;
}

.news-inner-wrapper li:last-child {
    margin-bottom: 0px;
}

.collection-detailsn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-left: 5px;
    cursor: pointer;
}


.collection-thumbnailn {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
    /* Make the image circular */
    flex-shrink: 0;
    /* Prevent the image from shrinking */
}

.collection-titlen {
    color: #fff;
    font-size: 1.1em;
    margin: 0;
    font-weight: bold;
    margin-bottom: 5px;
}

.collection-detailsn span {
    font-size: 12px;
}

.collection-detailsn {
    transition: opacity 0.3s, transform 0.3s;
    transform: scale(1);
    opacity: 1;
}

.news-inner-wrapper .collection-detailsn.transitioning {
    opacity: 0.2;
    transform: scale(0.0);
}
