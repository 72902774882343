.dashboard-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four equal columns */
    grid-template-rows: repeat(3, auto); /* Three rows with auto height */
    gap: 10px; /* spacing between grid items */
}

.news-wrapper {
    background-color: transparent;
    grid-column: 1 / 3; /* Spans the first two columns */
    grid-row: 1 / 2; /* Occupies the first row */
    z-index: 5;
}

.leaderboard-wrapper {
    background-color: transparent;
    grid-column: 3 / 5; /* Spans the third and fourth columns */
    grid-row: 1 / 4; /* Spans all three rows */
    height: 100vh;
    max-width: 600px;
    margin-left: 7.5%;
    margin-right: 7.5%;
    align-items: center;
    justify-content: center;
}

.trending-wrapper {
    background-color: transparent;
    grid-column: 1 / 2; /* Occupies the first column */
    grid-row: 2 / 4; /* Spans the second and third rows */
}

.last-purchases-wrapper {
    background-color: transparent;
    grid-column: 2 / 3; /* Occupies the second column */
    grid-row: 2 / 4; /* Spans the second and third rows */
}

@media (max-width: 768px) {
    .dashboard-container {
        display: flex;
        flex-direction: column; /* stack items vertically on smaller screens */
        align-items: flex-start;
        overflow: scroll;
        height: 100vh;
    }
    
    .trending-wrapper, .last-purchases-wrapper, .leaderboard-wrapper {
        margin-left: 10px;
        max-width: 400px; /* take full width on smaller screens */
        margin-top: 10px;
    }

}
