.top5-collections {
    background-color: #222;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
    padding: 20px;
    min-height: 350px;
}

.top5-collections li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: #eee;
}

.top5-collections li:last-child {
    margin-bottom: 0px;
}

.collection-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin-left: 5px;
}


.collection-thumbnail {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;
}


.collection-title {
    color: #fff;
    font-size: 1em;
    margin: 0;
    font-weight: bold;
}

.collection-details span {
    font-size: 12px;
}
