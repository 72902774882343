.favorites-page {
    height: 90vh;
    /* Full viewport height */
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Align content to the top */
    overflow: hidden;
}

.search-container {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    flex-direction: row;
}

.results-list {
    display: grid;
    grid-template-columns: max-content;
    /* Adapts to the content width */
    grid-gap: 15px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 80px);
    padding: 20px;
    justify-content: center;
    /* Center the grid container's content */
    justify-items: center;
    /* Center the grid items within their cells */
}

@media (min-width: 200px) {
    .results-list {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Responsive grid */
@media (min-width: 690px) {
    .search-container {
        flex-direction: row;
        align-items: stretch;
    }

    .search-barf {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }

    .results-list {
        grid-template-columns: repeat(2, 1fr);
        max-height: calc(100vh - 120px);
        padding: 10px;
        justify-content: stretch;
        /* Reset to default */
        justify-items: stretch;
        /* Reset to default */
    }

    .grid-item {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 1070px) {
    .results-list {
        grid-template-columns: repeat(3, 1fr);
        justify-content: stretch;
        /* Reset to default */
    }
}

@media (min-width: 1350px) {
    .results-list {
        grid-template-columns: repeat(4, 1fr);
        justify-content: stretch;
        /* Reset to default */
    }
}

@media (min-width: 1700px) {
    .results-list {
        grid-template-columns: repeat(5, 1fr);
        justify-content: stretch;
        /* Reset to default */
    }
}

@media (max-width: 950px) {
    .search-barf {
        max-width: 60%;
    }
}

@media (min-width: 690px) {
    .search-iconf {
        margin-top: 0;
    }
}

.search-barf {
    flex-grow: 1;
    padding: 10px 15px;
    font-size: 18px;
    border-radius: 25px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    outline: none;
    width: 80%;
    /* Adjust for responsiveness */
    margin-left: 10px;
    /* Space between the icon and the input */
}

.search-iconf {
    margin-top: 20px;
}

.card-link {
    text-decoration: none;  /* Remove the underline from the link */
    color: inherit;         /* Use the parent's color, so the link doesn't appear blue or any default link color */
    cursor: default;        /* Set the default cursor */
}

.card-link:hover {
    cursor: pointer;        /* Change the cursor to a pointer when hovering over the link */
}

.results-list-announcements {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    max-height: 500px;
    overflow-y: auto;
}


.announcement-card {
    display: flex;
    flex-direction: column;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    width: 80%;
}

.announcement-card:hover {
    transform: translateY(-5px);
}

.announcement-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}


.project-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    object-fit: cover;
    margin-right: 10px;
}

.project-name {
    font-size: 18px;
    font-weight: 600;
    color: #fab00a;
    margin-left: 5px;
}

.external-icon:focus {
    outline: none;
}

.project-time {
    position: absolute;
    right: 5px;
    color: #eee;
}

.announcement-content {
    font-size: 16px;
    color: #eee;
    line-height: 1.5;
    display: inline-block;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.expand-button {
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    font-size: 16px;
    text-decoration: underline;
}

.expand-button:focus {
    outline: none;
}

.blur-background {
    filter: blur(2px) brightness(0.7);
}

.custom-tabs {
    display: flex;
    border-bottom: 2px solid #ccc;
    background: linear-gradient(to right, #333, #666, #333); /* Add this line */
}

.custom-tab {
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    background-color: transparent; /* Add this line */
}

.custom-tab.active {
    border-bottom: 2px solid #fab00a;
    background-color: transparent; /* Add this line */
}

.dropdown-favorites-announcements {
    width: calc(100% - 20px);
    font-size: 16px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #333;
    color: white;
    border: none;
    outline: none;
    padding-right: 20px;
}

.arrow {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: #fab00a transparent transparent transparent;
    transition: transform 0.3s ease-in-out; /* Added transition for smooth rotation */
    pointer-events: none; /* This makes the arrow non-clickable, allowing clicks to pass through to the select element */
}

.dropdown-wrapper.open .arrow {
    transform: translateY(-50%) rotate(180deg); /* Rotate the arrow when dropdown is open */
}
