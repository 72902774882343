.profile-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.background-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    /* Set to 100% to make it stretch across the width of .user-profile */
    height: 50%;
    border-radius: 20px;
}


.user-profile {
    width: 70%;
    max-width: 800px;
    /* Adjust the maximum width as needed */
    
    background-color: #222;
    /* White background for the profile */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
}

.user-header {
    position: relative;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.edit-icon {
    cursor: pointer;
    /* Make it look clickable */
    margin-left: 10px;
    /* Add some space to the left */
}


.user-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: -10% 20px;
    display: block;
    border: 10px solid #222;
    background-color: #222;
}

.user-info-card {
    background-color: #000;
    /* White background for the inner card */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    position: relative;
    /* To ensure it's above the .background-card */
}


.user-display-name {
    color: #fff;
    /* White text color */
    font-size: 24px;
    /* Adjust the font size as needed */
    font-weight: bold;
}

.user-username {
    color: #fff;
    /* White text color */
    font-size: 16px;
    /* Adjust the font size as needed */
}

.user-last-seen{
    color: #fff;
    /* White text color */
    font-size: 12px;
    /* Adjust the font size as needed */
    margin-top: 10px;
}

.user-description,
.user-wallet-addr {
    color: #fff;
    /* White text color */
    font-size: 16px;
    /* Adjust the font size as needed */
    margin-top: 10px;
}


.user-details {
    margin-top: 20px;
}

.user-edit-options {
    text-align: center;
    margin-top: 20px;
}


.edit-input,
.edit-textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
}

.edit-textarea {
    height: 100px;
    resize: none;
}

.save-button,
.cancel-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-button-mobile,
.cancel-button-mobile {
    padding: 3px 5px;
    margin: 3px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50px;
    transition: background-color 0.3s;
}

.save-button-mobile {
    background-color: #3da240;
}

.cancel-button-mobile {
    background-color: #b8a1a0;
}

.save-button-mobile:hover {
    background-color: #c8cfc8;
}

.cancel-button-mobile:hover {
    background-color: #cfbdbb;
}

.user-wallet-addr {
    display: inline-flex;
    align-items: center;
}

.clipboard-icon {
    cursor: pointer;
    margin-left: 5px;
}

.save-button {
    background-color: #4CAF50;
    /* Green */
    color: #fff;
}

.cancel-button {
    background-color: #f44336;
    /* Red */
    color: #fff;
}

.save-button:hover {
    background-color: #45a049;
}

.cancel-button:hover {
    background-color: #da190b;
}

.input-group {
    margin-top: 10px;
}

.input-group label {
    display: block;
    color: #fff;
    margin-bottom: 5px;
    font-weight: bold;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: white;
    margin: 10px 0;
}

.display-name-container {
    display: flex;
    align-items: start;
}

.badge {
    display: inline-block;
    padding: 2px 10px;
    border-radius: 5px;
    margin-left: 20px;
    color: white;
    white-space: nowrap;
    font-size: 14px;
}

.xp-bar-container {
    display: flex;
    margin-left: 25%;
    margin-top: 10px;
}

.xp-bar {
    flex-grow: 1;
    height: 22.5px; /* Adjust as needed */
    background-color: #e0e0e0;
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
    overflow: hidden;
}

.xp-bar-fill {
    height: 100%;
    transition: width 0.3s ease-in-out;
}

.xp-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333; /* Adjust color as needed */
    font-weight: bold;
}

.xp-info {
    color: #fff;
    font-size: 12px;
    margin-top: 2px;
}

.level-text {
    margin-top: -2px;
    margin-left: 10px;
    font-weight: bold;
}

.user-rank-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
}

.user-rank-text {
    color: gray;
    font-weight: 500;
    font-size: small;
    margin-right: 5px;
}

.user-rank-number {
    margin-right: 5px;
}


@media (max-width: 768px) {
    .xp-bar-container {
        margin-left: 0; /* Remove the margin-left */
        margin-top: 20px; /* Add some space above the progress bar */
        width: 100%; /* Make it full width */
    }

    .user-avatar {
        margin: 0 auto 20px auto; /* Center the avatar and add some space below it */
    }

    .user-profile {
        width: 90%; /* Adjust the width for mobile */
    }
}

