.record-display::after {
    content: '';
    flex: 1;
}

.latest-purchases {
    font-family: 'Arial', sans-serif;
    background-color: #2a2a2a;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
    padding-right: 10px;
    padding-left: 10px;
    overflow-x: auto;
    max-width: 275px; /* Slightly increased */
    color: white;
    user-select: none;
    min-height: 350px;
}

.item-cell-p {
    position: relative;
    display: flex;
    align-items: center;
}

.item-image-wrapper-p {
    position: relative;
    margin-right: 10px;
    flex-shrink: 0; /* Add this line */
}

.item-type-p {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 8px;
    font-weight: 900;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    cursor: default;
    transition: transform 0.1s, background-color 0.2s;
    padding: 0.5px 2px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    min-width: 7.5px;
    line-height: 1;
}

.item-type-p.NFT {
    background-color: red;
}

.item-type-p.WL {
    background-color: green;
}

.item-type-p.OTHER {
    background-color: purple;
}

.record-display {
    padding: 10px;
    border-bottom: 1px solid #444;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 73.25px; /* Fixed height */
    overflow: hidden; /* Hide any content that exceeds the fixed height */
}

.record-display:last-child {
    border-bottom: none;
}


.record-display.fade-out {
    opacity: 0;
}

.record-display.fade-in {
    opacity: 1;
}

.record-display {
    opacity: 1;
    transition: opacity 0.3s ease-in-out; 
}

