@tailwind base;
@tailwind components;
@tailwind utilities;

/*PRODUCTION ONLY*/

body p {
    color: #f3f3f3;
}

h1 {
    color: #f3f3f3;
}

/*PRODUCTION ONLY*/

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #171717;
}

::-webkit-scrollbar-thumb {
    border-radius: 25px;
    border: 2px solid rgba(255, 255, 255, 0.7);
}

::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient(#d19900, #ffba00, #ffcc41);
}

.hover-scale {
    transition: background-color 0.3s, color 0.3s;
}

.hover-scale:hover {
    animation: bounce 0.3s forwards;
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1.05);
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}

.slide-in {
    animation: slideIn 0.5s forwards;
}

.slide-out {
    animation: slideOut 0.5s forwards;
}