.container {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-background {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    opacity: 0.5;
    object-fit: cover;
}

.card {
    background-color: rgba(23, 23, 23, 0.85);
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    text-align: center;
    z-index: 1;
}

.card h1 {
    color: #ffba00;
    font-size: xx-large;
    margin: 20px;
}

.card p {
    max-width: 300px;
    text-align: center;
    margin: auto;
    color: #ffba00;
}

.card a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffba00;
    color: #171717;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    margin: 20px;
}

.card a:hover {
    background-color: #e0a800;
    color: #fff;
    text-decoration: none;
    transform: scale(1.1);
    animation: bounce 0.3s;
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1.1);
    }
}