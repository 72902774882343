.news-containern {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    color: #fff;
}

.title-store-n {
    width: max-content;
    margin-bottom: 20px;
    padding: 20px;
    margin-left: 25px;
}

.title-store-n h1 {
    position: relative;
    font-size: 40px;
    color: transparent;
    overflow: hidden;
    font-weight: 500;
}

.title-store-n h1::before {
    content: "NEWSLETTER";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(to right, #fac20a, #da9808, #ba6f07);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    z-index: 9999;
}

.announcement-type {
    display: flex;
    justify-content: space-between;
    color: red;
}

.announcement-headern {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 10px;
    position: relative;
}

.results-list-news {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    max-height: 550px;
    overflow-y: auto;
}