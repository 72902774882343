.leaderboard-inner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/public/blueFrame.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    margin-top: 10px;
    min-height: 42vmax;
}

.leaderboard {
    border-radius: 8px;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
    padding: 30px;
    position: relative;
}

.top-players {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 75px;
}

.player-card {
    background-color: #333;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.player-link {
    text-decoration: none;
}

.player-card h2 {
    font-size: 20px;
}

.player-card p {
    font-size: 14px;
    margin-top: 5px;
}

.player-card.first {
    text-align: center;
    position: relative;
    order: 2;
    height: 180px;
    width: 150px;
    border-top-left-radius: 20%;
    border-top-right-radius: 20%;
    background-color: #fab00a;

}

.player-card h2 {
    color: #f5f5f5;
}

.player-card.first h2 {
    color: black;
    font-weight: 600;
}

.player-card p {
    color: #f5f5f5;
}

.player-card.first p {
    color: black;
}

.player-card.second {
    order: 1;
    height: 150px;
    width: 120px;
    border-top-left-radius: 20%;

}

.player-card.third {
    order: 3;
    height: 140px;
    width: 120px;
    border-top-right-radius: 20%;
}

.player-card .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: -90px;
}

.player-card img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-top: -17.5px;
}

.badge-lb {
    display: inline-block;
    padding: 2px 10px;
    border-radius: 5px;
    margin-left: 20px;
    color: white;
    white-space: nowrap;
    font-size: 14px;
    margin: 0 auto;
    margin-top: 5px;
}

.other-players {
    list-style: none;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    max-width: 500px;
    margin-left: 10%;
}

.other-players .list-item-contentl {
    display: flex;
    align-items: center;
    margin-bottom: 7.5px;
}

.other-players li:last-child .list-item-contentl {
    margin-bottom: 0;
    margin-left: -7.5px;
}

.other-players img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.other-players span {
    color: #fff;
}

/* New styles */
.other-players .level-xp-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
}

.other-players .level-xp-container span {
    margin: 0;
}

.medal {
    display: block;
}

.top-players .player-link.first {
    order: 2;
}

.top-players .player-link.second {
    order: 1;
}

.top-players .player-link.third {
    order: 3;
}

@media (max-width: 768px) {
    .other-players {
        margin-left: 7.5%;
        list-style: none;
        padding: 0;
    }

}