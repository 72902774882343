.search-page {
    height: 100vh;
    /* Full viewport height */
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Align content to the top */
    overflow: hidden;
}

.search-container {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    flex-direction: row;
}

.results-lists {
    display: grid;
    grid-template-columns: max-content;
    /* Adapts to the content width */
    grid-gap: 15px;
    width: 95%;
    margin-left: 5%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 80px);
    padding: 20px;
    justify-content: center;
    /* Center the grid container's content */
    justify-items: center;
    /* Center the grid items within their cells */
}

@media (min-width: 200px) {
    .results-lists {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Responsive grid */
@media (min-width: 690px) {
    .search-container {
        flex-direction: row;
        align-items: stretch;
    }

    .search-bar {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }

    .results-lists {
        grid-template-columns: repeat(2, 1fr);
        max-height: calc(100vh - 120px);
        padding: 10px;
        justify-content: stretch;
        /* Reset to default */
        justify-items: stretch;
        /* Reset to default */
    }

    .grid-item {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 1070px) {
    .results-lists {
        grid-template-columns: repeat(3, 1fr);
        justify-content: stretch;
        /* Reset to default */
    }
}

@media (min-width: 1350px) {
    .results-lists {
        grid-template-columns: repeat(4, 1fr);
        justify-content: stretch;
        /* Reset to default */
    }
}

@media (min-width: 1700px) {
    .results-lists {
        grid-template-columns: repeat(5, 1fr);
        justify-content: stretch;
        /* Reset to default */
    }
}

@media (max-width: 950px) {

    .search-bar,
    .search-icon,
    .order-button {
        margin-top: 40px;
    }

    .search-bar {
        max-width: 60%;
    }
}

.search-bar {
    flex-grow: 1;
    padding: 10px 15px;
    font-size: 18px;
    border-radius: 25px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    outline: none;
    width: 80%;
    /* Adjust for responsiveness */
    margin-left: 10px;
    /* Space between the icon and the input */
}

.truncate-text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Number of lines to display before truncating */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.styled-image {
    transition: transform 0.3s ease;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.styled-image:hover {
    transform: scale(1.05);
}

.search-icon {
    font-size: 24px;
    margin-top: 20px;
    /* Removed the absolute positioning and centering */
}

.no-results-img {
    max-width: 60%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    /* Center the image */
}

.card-link {
    text-decoration: none;
    /* Remove the underline from the link */
    color: inherit;
    /* Use the parent's color, so the link doesn't appear blue or any default link color */
    cursor: default;
    /* Set the default cursor */
}

.card-link:hover {
    cursor: pointer;
    /* Change the cursor to a pointer when hovering over the link */
}