.top-collections {
    margin-left: 5%;
    margin-right: 5%;
    padding: 20px;
    color: white;
    z-index: 1;
    background-color: transparent;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33.33%, 1fr));
    gap: 50px;
    transition: transform 0.4s ease;
    width: 100%;
}

.collection-card {
    position: relative;
    background-size: contain;
    /* Change to 'contain' to fit the image within the div */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    background-position: center;
    /* Center the image */
    padding: 15px;
    border-radius: 10px;
    height: 300px;
    /* Adjust the height as needed */
    overflow: hidden;
}


.collection-card h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
}

.collection-card p {
    font-size: 14px;
    color: #ffbb00;
}

.top-collections h2 {
    vertical-align: middle;
    font-size: 36px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
    margin-left: 5%;
}

.time-period-buttons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    margin-left: 20px;
}

.time-period-buttons button {
    padding: 5px 10px;
    border: 2px solid transparent;
    background-color: bg-neutral-900;
    color: white;
    cursor: pointer;
    border-radius: 15px;
    font-size: 14px;
    transition: background-color 0.3s, opacity 0.3s, border-color 0.3s;
}

.time-period-buttons button.selected {
    border-color: #ffba00;
}

.time-period-buttons button:hover {
    background-color: #e0a800;
}

.time-period-buttons button:active {
    opacity: 0.7;
}

.carousel-container {
    display: flex;
    align-items: center;
}

.arrow-style {
    align-self: center;
    margin-top: 125px;
}

.arrow-style:hover {
    cursor: pointer;
    color: #ffba00;
}

.left {
    margin-right: 10px;
}

.right {
    margin-left: 10px;
}

.collection-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    padding: 5px;
    text-align: center;
}

.title-with-border {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}



@keyframes floatText {
    0% {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(-200%);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinnerH {
    border: 4px solid rgba(255, 186, 0, 0.2);
    border-radius: 50%;
    border-top: 4px solid #ffba00;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    display: block;
}