.title-store {
    margin-left: 3%;
    user-select: none;
}

.icon-store {
    margin-top: 5px;
}

.text-store h1 {
    color: #ffab00;
    margin-left: 20px;
    font-size: 40px;
    font-family: Suez One;
}

.w h1::before {
    content: "WILD STORE";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(to right, #ffab00, #ffda77, #ff8e00);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
}

.search-refresh {
    position: relative;
    margin-right: -30%;
    margin-top: 10px;
}

.search-refresh *:focus {
    outline: none;
    box-shadow: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spin 0.25s linear;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-top: 7.5px;
    margin-left: 5%;
    width: 90%;
    font-size: 13px;
    font-family: 'Spartan';
    font-size: 15px;
}

.item-card-wrapper {
    width: 250px;
    border: 5px solid black;
    display: inline-block;
    background-color: black;
}

.item-card {
    background-color: black;
    border: 5px solid #ffab00;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.item-type {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffab00;
    border-bottom-right-radius: 5px;
    padding-left: 25px;
    padding-right: 25px;
    height: 25px;
    font-weight: 550;
}

.item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.main-content {
    transition: filter 0.3s ease;
}

.blurred-background {
    filter: blur(5px);
    pointer-events: none;
    /* make it non-clickable */
}

.image-modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
}

.expanded-image {
    margin-left: 30%;
    max-width: 40%;
    max-height: 40%;
    border-radius: 10px;
}

.close-modal-btn {
    position: absolute;
    top: 10px;
    right: 31%;
    background-color: #ffab00;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.item-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.item-name {
    color: wheat;
    font-weight: bold;
    margin: 0;
    font-size: 1.0em;
}

.floor-price {
    color: wheat;
    font-size: 0.9em;
    margin-top: 5px;
}

.quantity-left {
    color: wheat;
    font-size: 0.9em;
    margin-top: 2px;
}

.item-price {
    background-color: #ffab00;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.1s;
    text-align: center;
    font-weight: 500;
}


.item-price:hover {
    background-color: black;
    color: #ffab00;
    border: 2px solid #ffab00;
}

.item-price::before {
    content: attr(data-text);
}

.item-price:hover::before {
    padding-left: 13px;
    padding-right: 13px;
    content: "Purchase";
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
    user-select: none;
}

.chevron-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    /* Adjust as needed */
    height: 30px;
    /* Adjust as needed */
    border-radius: 50%;
    /* Makes it circular */
    background-color: #333;
    cursor: pointer;
}

.disabled .chevron-container {
    cursor: not-allowed;
    opacity: 0.5;
}

.page-number {
    background-color: #181818;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    color: wheat;
}

.page-number:hover {
    background-color: #f1c670;
    color: black;
}

.page-number.active {
    background-color: #ffab00;
    color: black;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.filter-buttons button {
    height: 25px;
    line-height: 15px;
    padding: 0 10px;
    font-size: 14px;
    border: 2px solid transparent;
    border-radius: 0;
    background-color: #111;
    /* Non-selected background */
    color: white;
    /* Default text color */
    cursor: pointer;
    transition: transform 0.1s, background-color 0.2s;
    margin: 2.5px;
    margin-right: 15px;
}

.filter-buttons button.ALL {
    color: grey;
}

.filter-buttons button.NFT {
    color: grey;
}

.filter-buttons button.WL {
    color: grey;
}

.filter-buttons button.OTHER {
    color: grey;
}

.filter-buttons button.selected {
    background-color: #252525;

}

.filter-buttons button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.filter-buttons button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter-buttons button:hover {
    transform: scale(1.05);
}

.filter-dropdown svg {
    fill: gray;
}

.mobile-search-container {
    display: flex;
    justify-content: space-between;
    width: 30%;
}

@media (max-width: 768px) {

    .title-store {
        margin-top: 50px;
    }

    .search-refresh {
        margin-right: 0;
        margin-left: 15px;
        list-style: none;
        padding: 0;
    }

    .search-inner-container {
        margin-top: 15px;
        flex-direction: column;
        align-items: flex-start;
    }

    .mobile-search-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
    }

}