.project-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.project-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.header-top {
    display: flex;
    align-items: center;
    gap: 10px;
}

.project-thumbnail {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 5px;
}

.star-icon {
    font-size: 24px;
    color: #fab00a;
    margin-top: -5px;
    cursor: pointer;
    user-select: none;
}

.star-icon:focus {
    outline: none;
}

.project-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.project-description {
    text-align: center;
    max-width: 1200px;
    color: #f5f5f5;
}

.project-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #444;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 60px;
    
}

.stat-title {
    font-size: 14px;
    color: #bbb;
    margin-top: 0px;
}

.stat-value {
    color: #fab00a;
    font-size: 18px;
    font-weight: bold;
    margin-top: -5px;
}

.loading-spinner {
    border: 4px solid rgba(255, 186, 0, 0.2);
    border-radius: 50%;
    border-top: 4px solid #ffba00;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    display: block;
    width: 20%;
    height: 20%;
    border-color: #000;
    background-color: #000; /* Consistent background color */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0; /* Resetting border-radius */
}

.loading-spinner::before {
    content: "";
    width: 40px;
    height: 40px;
    border: 5px solid transparent; /* Make all borders transparent */
    border-top-color: #fab00a; /* Only the top border will be visible */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.back-button {
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;  
    z-index: 10;
    transition: left 0.1s ease-in-out;
}


.image-modal-content {
    width: 75%;
}