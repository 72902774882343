.leaderboard-containerl {
    font-family: Arial, sans-serif;
    max-width: 80%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    color: #fff;
}

 .title-store {
    width: max-content;
}

.title-store h1 {
    position: relative;
    font-size: 40px;
    color: transparent;
    overflow: hidden;
    font-weight: 500;
}

.l h1::before {
    content: "LEADERBOARD";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(to right, #fac20a, #da9808, #ba6f07);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    z-index: 9999;
} 

.user-rowl {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #333;
    align-items: center;
    height: 75px;
}

.avatarl {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.avatarl img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto; /* Center the image inside the avatar div */
}

.detailsl p {
    margin: 0;
}

.detailsl small {
    opacity: 0.7;
}

.user-cardl {
    background-color: #333;
    border-radius: 5px;
    margin: 10px 0;
    padding: 5px;
}

/* Update user-row to remove border and adjust padding */
.user-rowl {
    display: flex;
    justify-content: center;
    padding: 5px 0;
    align-items: center;
    justify-content: center;
}

.positionl {
    margin-right: 15px;
    margin-left: 15px;
    justify-content: left;
}

.user-cardl .user-rowl > div {
    flex: 1;
    text-align: center;
}

.user-cardl .user-rowl > div:first-child {
    flex: 2;
    text-align: left;
}

.column-titlesl {
    font-weight: bold;
    height: 40px;
}

.user-infol {
    display: flex;
    align-items: center;
    flex: 2;
}

/* Adjust the styles for titles to make them align with data */
.user-title, .details p {
    flex: 1; /* allows them to take the available space */
}

.user-title {
    margin-left: 7.5%;

}

.level-title, .level, 
.xp-title, .xp,
.rank-title, .rank,
.position-title, .position {
    flex-basis: 20%; /* sets a fixed width for these columns */
    text-align: center; /* centers the titles and data */
}

.detailsl {
    flex: 1; /* allows it to take the available space */
}

.badges-containerl {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-containerl {
    width: 100%;
    max-width: 500px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    flex-direction: row;
    margin-left: 10%;
    
}

.search-barl {
    flex-grow: 1;
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 25px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    outline: none;
    width: 80%;
    margin-left: 10px;
}

.highlight {
    border: 2px solid #fab00a; /* Or any other style you prefer */
}
