.notification-fade-enter {
  opacity: 0;
  max-height: 0;
}

.notification-fade-enter-active {
  opacity: 1;
  max-height: 200px;
  transition: opacity 500ms, max-height 500ms;
  overflow: hidden;
}

.notification-fade-exit {
  opacity: 1;
  max-height: 200px;
}

.notification-fade-exit-active {
  opacity: 0;
  max-height: 0;
  transition: opacity 500ms, max-height 500ms;
  overflow: hidden;
}

.button-header {
  background-color: #171717;
}

.scaleOnHover {
  background-color: #ffba00;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.scaleOnHover:hover {
  transform: scale(1.05);
  background-color: #fde68a;
}